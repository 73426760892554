<template>
  <div v-click-outside="close">
    <div class="m-4 bg-white">
      <ic-cross
        class="absolute top-6 right-6"
        @close="close"
      />
      <div class="flex flex-col scroll-y-auto">
        <p class="text-bb-text-secondary pt-3">Search Term</p>
        <div class="flex flex-row items-center pt-1 pb-8 gap-x-2">
          <div class="flex flex-col">
            <h5 class="font-bold h5">
              {{ formatValue(keyword.searchTermView_searchTerm || keyword.keyword || keyword.keyword_text) }}
            </h5>
            <div
              v-if="keyword.tags?.infos?.length"
              class="flex flex-wrap gap-x-1"
            >
              <div
                v-for="(info, index) in keyword.tags.infos"
                :key="index"
              >
                <span
                  class="mt-1 text-xs font-bold rounded-md h-6 px-2 capitalize flex flex-row items-center"
                  :class="{
                    'bg-bb-decorative-cyan text-bb-solid-blue': info.toLowerCase() === 'relevant',
                    'bg-bb-light-yellow-brown text-bb-yellow-brown': info.toLowerCase() === 'irrelevant',
                    'bg-bb-pale-orange text-bb-dark-orange': info.toLowerCase() === 'best practice',
                    'bg-bb-blush-pink text-bb-solid-red':
                      info.toLowerCase() === 'poor performance (cpa)' ||
                      info.toLowerCase() === 'poor performance (cpc)' ||
                      info.toLowerCase() === 'poor performance (roas)' ||
                      info.toLowerCase() === 'best practice',
                    'bg-bb-light-green text-bb-solid-green':
                      info.toLowerCase() === 'strong performance (cpa)' ||
                      info.toLowerCase() === 'strong performance (cpc)',
                    'bg-bb-dull-purple text-bb-violet': info.toLowerCase() === 'high impact (cost)',
                    'bg-gray-200 text-gray-800': ![
                      'relevant',
                      'irrelevant',
                      'poor performance',
                      'strong performance',
                      'high impact (cost)',
                    ].includes(info.toLowerCase()),
                  }"
                >
                  {{ info }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col w-full max-h-200 base-scrollbar overflow-y-auto text-container pr-2">
        <div class="flex flex-row justify-between border-b border-neutral-50 py-2">
          <p class="font-medium text-left pr-5">Campaign</p>
          <p class="font-normal text-right break-words overflow-hidden">
            {{ formatValue(keyword.campaign_name || keyword.suggestion_json.campaign_name) }}
          </p>
        </div>
        <div class="flex flex-row justify-between border-b border-neutral-50 py-2">
          <p class="font-medium text-left">Campaign ID</p>
          <p class="font-normal text-right">
            {{ formatValue(keyword.campaign_id || keyword.suggestion_json.campaign_id) }}
          </p>
        </div>
        <div class="flex flex-row justify-between border-b border-neutral-50 py-2">
          <p class="font-medium text-left">Ad Group</p>
          <p class="font-normal text-right">{{ formatValue(keyword.adGroup_name || keyword.ad_group_name) }}</p>
        </div>
        <div class="flex flex-row justify-between border-b border-neutral-50 py-2">
          <p class="font-medium text-left">Ad Group ID</p>
          <p class="font-normal text-right">{{ formatValue(keyword.adGroup_id || keyword.ad_group_id) }}</p>
        </div>
        <div class="flex flex-row justify-between border-b border-neutral-50 py-2">
          <p class="font-medium text-left">Intent</p>
          <p class="font-normal text-right capitalize">{{ keyword.intent }} <span v-if="!keyword.intent">-</span></p>
        </div>
        <div class="flex flex-row justify-between border-b border-neutral-50 py-2">
          <p class="font-medium text-left pr-5">Date Range</p>
          <p class="font-normal text-right">
            {{ formatValue(keyword.data_date_range || keyword.suggestion_json.data_date_range) }}
          </p>
        </div>
        <div class="flex flex-row justify-between border-b border-neutral-50 py-2">
          <p class="font-medium text-left">Cost</p>
          <p class="font-normal text-right">
            {{ formatValue(keyword.metrics_cost || keyword.suggestion_json.metrics_cost) }}
            <span v-if="keyword.metrics_cost != null">{{ currency }}</span>
          </p>
        </div>
        <div class="flex flex-row justify-between border-b border-neutral-50 py-2">
          <p class="font-medium text-left">Impressions</p>
          <p class="font-normal text-right">
            {{ keyword.metrics_impressions || keyword.suggestion_json.metrics_impressions }}
          </p>
        </div>
        <div class="flex flex-row justify-between border-b border-neutral-50 py-2">
          <p class="font-medium text-left">Clicks</p>
          <p class="font-normal text-right">{{ keyword.metrics_clicks || keyword.suggestion_json.metrics_clicks }}</p>
        </div>
        <div class="flex flex-row justify-between border-b border-neutral-50 py-2">
          <p class="font-medium text-left">CTR</p>
          <p class="font-normal text-right">
            {{ Math.round(formatValue(keyword.metrics_ctr || keyword.suggestion_json.metrics_ctr) * 100) || '-' }}
            <span v-if="formatValue(keyword.metrics_ctr) != '-'">%</span>
          </p>
        </div>
        <div class="flex flex-row justify-between border-b border-neutral-50 py-2">
          <p class="font-medium text-left">CPC</p>
          <p class="font-normal text-right">
            {{ formatValue(keyword.metrics_cpc || keyword.suggestion_json.metrics_cpc) }}
            <span v-if="keyword.metrics_cpc != null">{{ currency }}</span>
          </p>
        </div>
        <div class="flex flex-row justify-between border-b border-neutral-50 py-2">
          <p class="font-medium text-left">Campaign CPC</p>
          <p class="font-normal text-right">
            {{ formatValue(keyword.metrics_cpc_campaign || keyword.suggestion_json.metrics_cpc_campaign) }}
            <span v-if="keyword.metrics_cpc_campaign != null">{{ currency }}</span>
          </p>
        </div>
        <div class="flex flex-row justify-between border-b border-neutral-50 py-2">
          <p class="font-medium text-left">CPA</p>
          <p class="font-normal text-right">
            {{ formatValue(keyword.metrics_cpa) || formatValue(keyword.suggestion_json.metrics_cpa) }}
            <span v-if="keyword.metrics_cpa != null">{{ currency }}</span>
          </p>
        </div>
        <div class="flex flex-row justify-between border-b border-neutral-50 py-2">
          <p class="font-medium text-left">Campaign CPA</p>
          <p class="font-normal text-right">
            {{
              formatValue(keyword.metrics_cpa_campaign, 0) ||
              formatValue(keyword.suggestion_json.metrics_cpa_campaign, 0)
            }}
            <span v-if="keyword.metrics_cpa_campaign != null">{{ currency }}</span>
          </p>
        </div>
        <div class="flex flex-row justify-between border-b border-neutral-50 py-2">
          <p class="font-medium text-left">Conversions</p>
          <p class="font-normal text-right">
            {{
              keyword.metrics_conversions !== 0
                ? formatValue(keyword.metrics_conversions || keyword.suggestion_json.metrics_conversions, 0)
                : '-'
            }}
          </p>
        </div>
        <div class="flex flex-row justify-between border-b border-neutral-50 py-2">
          <p class="font-medium text-left pr-5">Conversions Value</p>
          <p class="font-normal text-right">
            {{
              keyword.metrics_conversionsValue !== 0
                ? formatValue(keyword.metrics_conversionsValue || keyword.suggestion_json.metrics_conversionsValue, 0)
                : '-'
            }}
          </p>
        </div>
        <div class="flex flex-row justify-between border-b border-neutral-50 py-2">
          <p class="font-medium text-left">Conversion Rate</p>
          <p class="font-normal text-right">
            {{ Math.round(formatValue(keyword.metrics_cvr) * 100) || '-' }}
            <span v-if="formatValue(keyword.metrics_cvr) !== '-'">%</span>
          </p>
        </div>
        <div class="flex flex-row justify-between border-b border-neutral-50 py-2">
          <p class="font-medium text-left">ROAS</p>
          <p class="font-normal text-right">
            {{ formatValue(Math.round(keyword.metrics_roas * 100) || formatValue(keyword.metrics_roas)) }}
            <span v-if="formatValue(keyword.metrics_roas) !== '-'">%</span>
          </p>
        </div>
        <div class="flex flex-row justify-between border-b border-neutral-50 py-2">
          <p class="font-medium text-left">Campaign ROAS</p>
          <p class="font-normal text-right">
            {{
              formatValue(
                Math.round((keyword.metrics_roas_campaign ?? keyword.suggestion_json.metrics_roas_campaign ?? 0) * 100),
              )
            }}
            <span v-if="formatValue(keyword.metrics_roas_campaign) !== '-'">%</span>
          </p>
        </div>
        <div class="flex flex-row justify-between border-b border-neutral-50 py-2">
          <p class="font-medium text-left">Target</p>
          <p class="font-normal text-right">
            {{ formatValue(keyword.target_type || keyword.suggestion_json.target_type) }}
          </p>
        </div>
        <div class="flex flex-row justify-between border-b border-neutral-50 py-2">
          <p class="font-medium text-left">Target Value</p>
          <p class="font-normal text-right">
            {{ formatValue(keyword?.target_value || keyword?.suggestion_json?.target_value || '-') }}
          </p>
        </div>
        <div class="flex flex-row justify-between border-b border-neutral-50 py-2">
          <p class="font-medium text-left">CVR vs Campaign</p>
          <p class="font-normal text-right">
            {{ formatValue(Math.round(keyword.cvr_vs_campaign)) * 100 || '-' }}
            <span v-if="formatValue(keyword.cvr_vs_campaign) !== '-'">%</span>
          </p>
        </div>
        <div class="flex flex-row justify-between border-b border-neutral-50 py-2">
          <p class="font-medium text-left">All Conv. Rate vs Campaign</p>
          <p class="font-normal text-right">
            {{ formatValue(keyword.allConvRate_vs_campaign, 0) * 100 || '-' }}
            <span v-if="formatValue(keyword.allConvRate_vs_campaign) !== '-'">%</span>
          </p>
        </div>
        <div class="flex flex-row justify-between border-b border-neutral-50 py-2">
          <p class="font-medium text-left">CPC vs Campaign</p>
          <p class="font-normal text-right">
            {{ formatValue(Math.round(keyword.cpc_vs_campaign)) * 100 || '-' }}
            <span v-if="keyword.cpc_vs_campaign !== '-'">%</span>
          </p>
        </div>
        <div class="flex flex-row justify-between border-b border-neutral-50 py-2">
          <p class="font-medium text-left">CPA vs Campaign</p>
          <p class="font-normal text-right">
            {{ formatValue(Math.round(keyword.cpa_vs_campaign)) * 100 || '-' }}
            <span v-if="formatValue(keyword.cpa_vs_campaign) !== '-'">%</span>
          </p>
        </div>
      </div>
      <div class="pt-5 mb-4 flex flex-row gap-4">
        <MergeButtonRound
          v-for="(button, index) in buttons"
          :key="index"
          brightbid
          :button-type="button.type || 'primary'"
          class="w-full"
          v-on="button.events"
        >
          <div class="flex gap-2 items-center">
            <component
              :is="button.icon"
              :size="16"
              :class="button.iconClass || ''"
            />
            <p>{{ button.label }}</p>
          </div>
        </MergeButtonRound>
      </div>
    </div>
    <EditPanel
      v-if="isEditPanelVisible"
      :keyword="keyword"
      :currency="currency"
      @close="closeEditPanel"
    />
  </div>
</template>
<script>
import IcPlus from 'vue-material-design-icons/Plus.vue'
import IcCross from '@/components/icon/ic-cross.vue'
import IcCurvedArrow from '@/components/icon/brightbid/ic-curved-arrow.vue'
import IcPause from '@/components/icon/brightbid/ic-pause.vue'

import EditPanel from '@/views/keywords_suggestions/EditPanel.vue'

export default {
  name: 'EditPanel',
  components: { IcCross, IcPlus, IcCurvedArrow, EditPanel, IcPause },
  props: {
    keyword: {
      type: Object,
      required: true,
    },
    currency: {
      type: String,
      required: false,
      default: '',
    },
    context: {
      type: String,
      required: true,
      validator: value => ['keywordsuggestions', 'activityhistory'].includes(value),
    },
    buttons: {
      type: Array,
      required: true,
      validator: value =>
        value.every(
          button =>
            typeof button.label === 'string' &&
            typeof button.events === 'object' &&
            Object.values(button.events).every(handler => typeof handler === 'function'),
        ),
    },
  },
  data() {
    return { isEditPanelVisible: false }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    handleClick() {
      this.$emit('handleClick')
    },
    reject() {
      this.$emit('reject')
    },
    undo() {
      this.$emit('undo')
    },
    add() {
      this.$emit('add')
    },
    formatValue(value, decimals = 1) {
      if (value === null || value === undefined || value === 'N/A' || value === 0) {
        return '-'
      }
      if (typeof value === 'number') {
        // Treat numbers as zero if they are below a small threshold (e.g., 0.01)
        const threshold = 0.01
        if (Math.abs(value) < threshold) {
          return '-'
        }
        // Handle integer numbers
        if (value % 1 === 0) {
          return value.toString()
        }
        // Handle general floating-point numbers
        const formattedValue = value.toFixed(decimals) // Use the decimals parameter
        if (formattedValue.endsWith('.0')) {
          return parseInt(formattedValue).toString() // Remove decimal if it ends with .0
        }
        return formattedValue
      }
      if (typeof value === 'string' && value.includes(',')) {
        // Handle date ranges in string format
        const dates = value.split(',').map(date => new Date(date.trim()))
        if (dates.length === 2 && !isNaN(dates[0]) && !isNaN(dates[1])) {
          const options = { year: 'numeric', month: 'long', day: 'numeric' }
          return `${dates[0].toLocaleDateString('en-US', options)} - ${dates[1].toLocaleDateString('en-US', options)}`
        }
      }
      return value
    },

    openEditPanel() {
      this.isEditPanelVisible = true
    },
    closeEditPanel() {
      this.isEditPanelVisible = false
    },
  },
}
</script>

<style scoped lang="scss">
.text-container {
  height: calc(100vh - 320px);
}
</style>
