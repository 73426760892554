<template>
  <div class="flex flex-col items-center">
    <img
      v-if="emptyState.imageSrc"
      :src="emptyState.imageSrc"
      alt=""
    />
    <h5 class="h5">{{ emptyState.title }}</h5>
    <p>{{ emptyState.subtitle }}</p>
  </div>
</template>

<script>
export default {
  name: 'TableEmptyStates',

  props: {
    status: {
      type: String,
      required: true,
      validator: value => ['positive', 'negative', 'pause', 'all'].includes(value),
    },
  },
  computed: {
    emptyState() {
      const emptyStateClass = {
        positive: {
          imageSrc: '/content/images/brightbid/robot-empty-page.svg',
          title: 'No Positive Keywords',
          subtitle: 'You have no positive keywords at the moment.',
        },
        negative: {
          imageSrc: '/content/images/brightbid/robot-empty-page.svg',
          title: 'No Negative Keywords',
          subtitle: 'You have no negative keywords at the moment.',
        },
        pause: {
          imageSrc: '/content/images/brightbid/robot-empty-page.svg',
          title: 'No Paused Keywords',
          subtitle: 'You have no paused keywords at the moment.',
        },
        all: {
          imageSrc: '/content/images/brightbid/robot-empty-page.svg',
          title: 'No Keywords',
          subtitle: 'You have no keywords at the moment.',
        },
      }
      return emptyStateClass[this.status] || emptyStateClass.all
    },
  },
}
</script>
