<template>
  <ul
    class="pagination"
    role="menubar"
    aria-label="Pagination"
  >
    <li
      class="pagination-button"
      :class="{ disabled: checkPrevious || hasOnlyOnePage }"
    >
      <MergeButtonRound
        button-type="secondary"
        :disabled="checkPrevious || hasOnlyOnePage"
        @click="previousPage"
      >
        <ic-chevron
          direction="left"
          :class="checkPrevious || hasOnlyOnePage ? 'text-bb-mid-grey' : 'text-bb-brand-purple'"
        />
      </MergeButtonRound>
    </li>

    <li
      v-for="num in pager"
      :key="num"
      :class="{ current: num === page }"
      @click="num === '...' ? null : goToPage(num)"
    >
      <span>{{ num }}</span>
    </li>

    <li
      class="pagination-button"
      :class="{ disabled: checkLast || hasOnlyOnePage }"
    >
      <MergeButtonRound
        button-type="secondary"
        :disabled="checkLast || hasOnlyOnePage"
        @click="nextPage"
      >
        <ic-chevron
          direction="right"
          :class="checkLast || hasOnlyOnePage ? 'text-bb-mid-grey' : 'text-bb-brand-purple'"
        />
      </MergeButtonRound>
    </li>
  </ul>
</template>

<script>
import MergeButtonRound from '@/components/btn/MergeButtonRound'
import IcChevron from '@/components/icon/ic-chevron'

export default {
  name: 'TablePagination',
  components: { MergeButtonRound, IcChevron },
  props: {
    max: {
      type: Number,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    limit: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      paginationData: {
        total: null,
        per_page: null,
        current_page: null,
        last_page: null,
        from: null,
        to: null,
      },
    }
  },
  computed: {
    checkPrevious() {
      return this.page === 1
    },
    checkLast() {
      return this.page >= this.paginationData.last_page
    },
    pager() {
      let pagination = [],
        i = 1
      if (this.paginationData.last_page <= 1) {
        pagination.push(i)
        return pagination
      }
      if (this.paginationData.last_page < 7) {
        for (let j = 1; j <= this.paginationData.last_page; j++) {
          pagination.push(j)
        }
      } else {
        while (i <= this.paginationData.last_page) {
          if (i <= 2 || i >= this.paginationData.last_page - 2 || (i >= this.page - 1 && i <= this.page + 1)) {
            pagination.push(i)
            i++
          } else {
            pagination.push('...')
            i = i < this.page ? this.page - 1 : this.paginationData.last_page - 2
          }
        }
      }
      return pagination
    },
    hasOnlyOnePage() {
      return this.limit > this.max
    },
  },
  watch: {
    page(number) {
      this.paginationData = { ...this.paginationData, ...this.pagination(this.max, number, this.limit) }
    },
    limit(limit) {
      this.paginationData = { ...this.paginationData, ...this.pagination(this.max, this.page, limit) }
    },
    max() {
      this.paginationData = { ...this.paginationData, ...this.pagination(this.max, this.page, this.limit) }
    },
  },
  mounted() {
    this.paginationData = { ...this.paginationData, ...this.pagination(this.max, this.page, this.limit) }
  },
  methods: {
    pagination(length, currentPage, itemsPerPage) {
      return {
        total: length,
        per_page: itemsPerPage,
        current_page: currentPage,
        last_page: Math.ceil(length / itemsPerPage),
        from: (currentPage - 1) * itemsPerPage + 1,
        to: currentPage * itemsPerPage,
      }
    },
    goToPage(number) {
      if (number !== this.page) {
        this.$emit('onChange', this.pagination(this.max, number, this.limit))
      }
    },
    nextPage() {
      if (this.checkLast) {
        return
      }
      this.$emit('onChange', this.pagination(this.max, this.page + 1, this.limit))
    },
    goLastPage() {
      if (this.checkLast) {
        return
      }
      this.$emit('onChange', this.pagination(this.max, this.paginationData.last_page, this.limit))
    },
    goFirstPage() {
      if (this.checkPrevious) {
        return
      }
      this.$emit('onChange', this.pagination(this.max, 1, this.limit))
    },
    previousPage() {
      if (this.checkPrevious) {
        return
      }
      this.$emit('onChange', this.pagination(this.max, this.page - 1, this.limit))
    },
  },
}
</script>

<style scoped lang="scss">
.pagination {
  @apply list-none flex flex-wrap justify-center items-center gap-5;

  &-button {
    &.disabled {
      span {
        @apply cursor-default text-bb-mid-grey select-none;
      }
    }
  }

  li span {
    @apply flex flex-wrap justify-center items-center;
  }

  span {
    @apply text-bb-brand-purple font-light pt-1 border-l-0 cursor-pointer underline;
  }

  li {
    &:not([class*='current']) span:hover {
      @apply text-bb-dark-grey;
    }

    &.current span {
      @apply text-black cursor-default pointer-events-none no-underline;
    }
  }
}

@media only screen and (max-width: 64.063em) {
  .pagination li:first-child,
  .pagination li:last-child {
    /* screen readers only */
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
}

@media only screen and (max-width: 40.063em) {
  .pagination li {
    /* screen readers only */
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .pagination li.current,
  .pagination li:first-of-type,
  .pagination li:last-of-type,
  .pagination li:nth-of-type(2),
  .pagination li:nth-last-of-type(2) {
    position: initial;
    top: initial;
    left: initial;
  }
}

@media only screen and (max-width: 30.063em) {
  .pagination li:first-child,
  .pagination li:last-child {
    /* screen readers only */
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
}

@media only screen and (max-width: 15.063em) {
  .pagination li {
    width: 50%;
  }

  .pagination li.current {
    order: 2;
    width: 100%;
  }
}
</style>
