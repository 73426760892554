<template>
  <div class="flex bg-neutral-0 rounded-md">
    <button
      v-for="filter in filters"
      :key="filter.value"
      :class="[
        'focus:outline-none',
        'flex flex-row gap-x-3 items-center transition-colors duration-300',
        buttonTypeClasses,
        { 'bg-white shadow-bb-shadow': currentFilter === filter.value },
      ]"
      @click="changeFilter(filter.value)"
    >
      <div
        v-if="showStatus"
        class="h-2 w-2 rounded-full"
        :class="getStatusStyling(filter.value)"
      ></div>
      {{ filter.label }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'FilterButtons',
  props: {
    currentFilter: {
      type: String,
      required: true,
    },
    filters: {
      type: Array,
      required: true,
    },
    buttonType: {
      type: String,
      required: false,
      default: 'primary',
    },
    showStatus: {
      type: Boolean,
      required: false,
      default: false,
    },
    statusClasses: {
      type: Object,
      required: false,
      default: () => ({
        positive: 'bg-success',
        negative: 'bg-bb-error',
        pause: 'bg-bb-warning',
      }),
    },
  },
  computed: {
    // Add more cases here for other styling if needed
    buttonTypeClasses() {
      switch (this.buttonType) {
        case 'primary':
          return 'text-bb-neutral-gray font-bold h-8 m-1 px-4 rounded-md hover:bg-white'
        default:
          return ''
      }
    },
  },
  methods: {
    changeFilter(filter) {
      this.$emit('filter-change', filter)
    },
    // Use statusClasses as a prop for other styling if needed
    getStatusStyling(status) {
      return this.statusClasses[status] || 'hidden'
    },
  },
}
</script>
