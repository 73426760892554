<template>
  <div class="pt-10">
    <h4 class="h4">Advanced Setup</h4>
    <form
      class="pt-8 space-y-5"
      @submit.prevent="onSubmit"
    >
      <p><span class="font-bold">Minimum Cost Threshold</span> (Last 60 Days)</p>
      <div class="pl-6">
        <div class="flex flex-row gap-x-2 items-center">
          <p>
            Only suggest keywords with a minimum Cost of
            <input
              v-model.number="formData.neg_pause_pos_cost_min"
              type="number"
              class="w-11 h-8 border border-neutral-100 rounded-md text-right pr-2"
            />
            {{ currency }} in the past 60 days.
          </p>
          <new-tooltip class="pb-1">
            <template #content>{{ tooltipContent.neg_pause_pos_cost_min }}</template></new-tooltip
          >
        </div>
      </div>
      <p>
        <span class="font-bold py-1">Never suggest</span> a single negative keyword or to pause a single keyword with:
      </p>
      <div class="space-y-5 pl-6">
        <div class="flex flex-row gap-x-2 items-center">
          <input
            v-model.number="formData.neg_pause_conv_threshold"
            type="number"
            class="w-11 h-8 border border-neutral-100 rounded-md text-right pr-2"
            :class="{ 'border-bb-error': $v.formData.neg_pause_conv_threshold.$error }"
          />
          <p>or more conversions last 60 days.</p>
          <new-tooltip class="pb-1">
            <template #content>{{ tooltipContent.neg_pause_conv_threshold }}</template></new-tooltip
          >
          <div
            v-if="$v.formData.neg_pause_conv_threshold.$error"
            class="text-bb-error text-xs"
          >
            The lowest value to insert is 1.
          </div>
        </div>
        <div class="flex flex-row gap-x-2 items-center">
          <p>more than</p>
          <input
            v-model.number="formData.neg_pause_click_ratio"
            type="number"
            class="w-13 h-8 border border-neutral-100 rounded-md text-right pr-2"
            :class="{
              'border-bb-error': $v.formData.neg_pause_click_ratio.$error,
            }"
          />
          <p>% of the total campaign clicks.</p>
          <new-tooltip class="pb-1">
            <template #content>{{ tooltipContent.neg_pause_click_ratio }}</template></new-tooltip
          >
          <div
            v-if="$v.formData.neg_pause_click_ratio.$error"
            class="text-bb-error text-xs"
          >
            Insert a value between 0 and 100.
          </div>
        </div>
      </div>

      <p class="font-bold py-1">Max Click Reduction Impact (%):</p>
      <div class="space-y-5 pl-6">
        <div class="flex flex-row gap-x-2 items-center">
          <p>Limit the potential impact of <span class="font-bold">negative</span> suggestions to</p>
          <input
            v-model.number="formData.neg_total_clicks_per_campaign_ratio"
            type="number"
            class="w-13 h-8 border border-neutral-100 rounded-md text-right pr-2"
            :class="{
              'border-bb-error': $v.formData.neg_total_clicks_per_campaign_ratio.$error,
            }"
          />
          <p>% of the campaign clicks.</p>
          <new-tooltip class="pb-1">
            <template #content>{{ tooltipContent.neg_total_clicks_per_campaign_ratio }}</template></new-tooltip
          >
          <div
            v-if="$v.formData.neg_total_clicks_per_campaign_ratio.$error"
            class="text-bb-error text-xs"
          >
            Insert a value between 0 and 100.
          </div>
        </div>

        <div class="flex flex-row gap-x-2 items-center">
          <p>Limit the potential impact of <span class="font-bold">pause</span> suggestions to</p>
          <input
            v-model.number="formData.pause_total_clicks_per_campaign_ratio"
            type="number"
            class="w-13 h-8 border border-neutral-100 rounded-md text-right pr-2"
            :class="{
              'border-bb-error': $v.formData.pause_total_clicks_per_campaign_ratio.$error,
            }"
          />
          <p>% of the campaign clicks.</p>
          <new-tooltip class="pb-1">
            <template #content>{{ tooltipContent.pause_total_clicks_per_campaign_ratio }}</template></new-tooltip
          >
          <div
            v-if="$v.formData.pause_total_clicks_per_campaign_ratio.$error"
            class="text-bb-error text-xs"
          >
            Insert a value between 0 and 100.
          </div>
        </div>
      </div>
      <p class="font-bold py-1">Number of suggestions:</p>
      <div class="space-y-5 pl-6">
        <div class="flex flex-row gap-x-2 items-center">
          <p>Limit the number of <span class="font-bold">positive</span> suggestions per campaign to</p>
          <input
            v-model.number="formData.pos_suggestion_count_per_campaign"
            type="number"
            class="w-11 h-8 border border-neutral-100 rounded-md text-right pr-2"
            :class="{
              'border-bb-error': $v.formData.pos_suggestion_count_per_campaign.$error,
            }"
          />
          <p>.</p>
          <div
            v-if="$v.formData.pos_suggestion_count_per_campaign.$error"
            class="text-bb-error text-xs"
          >
            The lowest value to insert is 5.
          </div>
        </div>

        <div class="flex flex-row gap-x-2 items-center">
          <p>Limit the number of <span class="font-bold">negative</span> suggestions per campaign to</p>
          <input
            v-model.number="formData.neg_suggestion_count_per_campaign"
            type="number"
            class="w-11 h-8 border border-neutral-100 rounded-md text-right pr-2"
            :class="{
              'border-bb-error': $v.formData.neg_suggestion_count_per_campaign.$error,
            }"
          />
          <p>.</p>
          <div
            v-if="$v.formData.neg_suggestion_count_per_campaign.$error"
            class="text-bb-error text-xs"
          >
            The lowest value to insert is 5.
          </div>
        </div>

        <div class="flex flex-row gap-x-2 items-center">
          <p>Limit the number of <span class="font-bold">pause</span> suggestions per campaign to</p>
          <input
            v-model.number="formData.pause_suggestion_count_per_campaign"
            type="number"
            class="w-11 h-8 border border-neutral-100 rounded-md text-right pr-2"
            :class="{
              'border-bb-error': $v.formData.pause_suggestion_count_per_campaign.$error,
            }"
          />
          <p>.</p>
          <div
            v-if="$v.formData.pause_suggestion_count_per_campaign.$error"
            class="text-bb-error text-xs"
          >
            The lowest value to insert is 5.
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { minValue, maxValue } from 'vuelidate/lib/validators'
import NewTooltip from '@/components/alert/NewTooltip.vue'

export default {
  name: 'AdvancedSetUp',
  components: {
    NewTooltip,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
    siteId: {
      type: Number,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      formData: {
        neg_pause_conv_threshold: 1,
        neg_pause_click_ratio: 0,
        neg_total_clicks_per_campaign_ratio: 20,
        pause_total_clicks_per_campaign_ratio: 20,
        pos_suggestion_count_per_campaign: 20,
        neg_suggestion_count_per_campaign: 20,
        pause_suggestion_count_per_campaign: 20,
        neg_pause_pos_cost_min: 0,
      },
    }
  },
  validations() {
    return {
      formData: {
        neg_pause_conv_threshold: { minValue: minValue(1) },
        neg_pause_click_ratio: { minValue: minValue(0), maxValue: maxValue(100) },
        neg_total_clicks_per_campaign_ratio: { minValue: minValue(0), maxValue: maxValue(100) },
        pause_total_clicks_per_campaign_ratio: { minValue: minValue(0), maxValue: maxValue(100) },
        pos_suggestion_count_per_campaign: { minValue: minValue(5) },
        neg_suggestion_count_per_campaign: { minValue: minValue(5) },
        pause_suggestion_count_per_campaign: { minValue: minValue(5) },
      },
    }
  },
  computed: {
    tooltipContent() {
      return {
        neg_pause_conv_threshold:
          'Keyword pause or negative suggestions will only apply to keywords with fewer than X conversions in the last 60 days. Leave empty to disable this limit.',
        neg_pause_click_ratio:
          'Set the maximum percentage of total campaign clicks that can be impacted by applying any single negative or pause suggestion. For example, a value of 5% ensures that a single suggested changes won’t reduce total clicks by more than 5%.',
        neg_total_clicks_per_campaign_ratio:
          'Set the maximum percentage of total campaign clicks that can be impacted by applying all suggested negative keywords. For example, a value of 20% ensures that suggested changes won’t reduce total clicks by more than 20%.',
        pause_total_clicks_per_campaign_ratio:
          'Set the maximum percentage of total campaign clicks that can be impacted by applying all pause suggestions. For example, a value of 20% ensures that suggested changes won’t reduce total clicks by more than 20%.',
        neg_pause_pos_cost_min:
          'This threshold does not apply to relevance suggestions. Reducing or removing this threshold will generate more suggestions. Increasing threshold will generate less suggestions.',
      }
    },
  },
  async created() {
    await this.fetchSettings()
  },
  methods: {
    async fetchSettings() {
      try {
        const { data } = await this.$http.get(`/search/site/${this.siteId}/kws-settings`)
        this.formData = {
          neg_pause_conv_threshold: data.neg_pause_conv_threshold,
          neg_pause_click_ratio: data.neg_pause_click_ratio * 100,
          neg_total_clicks_per_campaign_ratio: (data.neg_total_clicks_per_campaign_ratio || 0) * 100,
          pause_total_clicks_per_campaign_ratio: (data.pause_total_clicks_per_campaign_ratio || 0) * 100,
          pos_suggestion_count_per_campaign: data.pos_suggestion_count_per_campaign || 20,
          neg_suggestion_count_per_campaign: data.neg_suggestion_count_per_campaign || 20,
          pause_suggestion_count_per_campaign: data.pause_suggestion_count_per_campaign || 20,
          neg_pause_pos_cost_min: data.neg_pause_pos_cost_min || 0,
        }
      } catch (error) {
        console.error('Failed to fetch settings:', error.response?.data || error.message)
      }
    },
    async saveSettings() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        throw new Error('Form validation failed. Please check the form for errors.')
      }

      try {
        const siteId = this.context.site_id
        if (!siteId) {
          console.error('Search customer id was not found in context.')
          return
        }
        this.isLoading = true

        const payload = {
          siteId: this.siteId,
          neg_pause_conv_threshold: this.formData.neg_pause_conv_threshold,
          neg_pause_click_ratio: this.formData.neg_pause_click_ratio / 100,
          neg_total_clicks_per_campaign_ratio: this.formData.neg_total_clicks_per_campaign_ratio / 100,
          pause_total_clicks_per_campaign_ratio: this.formData.pause_total_clicks_per_campaign_ratio / 100,
          pos_suggestion_count_per_campaign: this.formData.pos_suggestion_count_per_campaign,
          neg_suggestion_count_per_campaign: this.formData.neg_suggestion_count_per_campaign,
          pause_suggestion_count_per_campaign: this.formData.pause_suggestion_count_per_campaign,
          neg_pause_pos_cost_min: this.formData.neg_pause_pos_cost_min,
        }

        const { data: addedExpression } = await this.$http.post(`/search/site/${this.siteId}/kws-settings`, payload)
        this.$emit('update-setup', addedExpression)
      } catch (error) {
        console.error('Failed to update settings:', error.response?.data || error.message)
      } finally {
        this.isLoading = false
      }
    },
    onSubmit() {
      this.$emit('submit-settings', { formData: this.formData })
    },
    onCheckboxChange() {
      this.$emit('checkbox-updated', { formData: this.formData })
    },
  },
}
</script>

<style scoped>
/* This removes the default step buttons */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
